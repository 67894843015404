<template>
  <div>
    <header class="main-header">
      <h1>
        {{ $options.CONFIG.name }}
        <span class="dates">
          {{ $options.CONFIG.dates }}
        </span>
      </h1>
      <a
        :href="$options.CONFIG.baseUrl + $options.CONFIG.zipFileName"
        class="download"
      >Download All Photos <span style="font-weight: 400;">({{ $options.CONFIG.zipFileSize }})</span></a>
    </header>

    <vue-picture-swipe
      :items="items"
      :options="$options.CONFIG.photoSwipeOptions"
    />
  </div>
</template>

<script>
import VuePictureSwipe from 'vue-picture-swipe'
import Photos from '@/data/photos.json'

export default {
  components: {
    VuePictureSwipe
  },
  CONFIG: {
    baseUrl: 'https://tinymachine.s3.amazonaws.com/won-park-slideshow-photos/',
    dates: '1917–2018',
    directoryFullSize: 'photos/',
    directoryThumbnail: 'thumbs/',
    fileExtension: 'jpg',
    filenamePrefixFullSize: 'won-park-photo-',
    filenamePrefixThumbnail: 'won-park-thumb-',
    name: 'Won Kyung Park',
    photoSwipeOptions: {
      bgOpacity: 0.85,

      // show only download option, not other defaults (FB/Twitter/Pinterest)
      shareButtons: [
        {
          id: 'download',
          label: 'Download Photo',
          url: '{{raw_image_url}}',
          download: true
        }
      ]
    },
    zipFileSize: '28 MB',
    zipFileName: 'won-park-slideshow-photos.zip'
  },
  computed: {
    items () {
      let photos = []

      const prefixFullSize =
        this.$options.CONFIG.baseUrl +
        this.$options.CONFIG.directoryFullSize +
        this.$options.CONFIG.filenamePrefixFullSize

      const prefixThumbnail =
        this.$options.CONFIG.baseUrl +
        this.$options.CONFIG.directoryThumbnail +
        this.$options.CONFIG.filenamePrefixThumbnail

      const suffix = '.' + this.$options.CONFIG.fileExtension

      for (let i = 0; i < Photos.length; i++) {
        photos.push(
          {
            src: prefixFullSize + Photos[i].photoNum + suffix,
            thumbnail: prefixThumbnail + Photos[i].photoNum + suffix,
            w: Photos[i].w,
            h: Photos[i].h
          }
        )
      }

      return photos
    }
  }
}
</script>

<style lang="scss">
$gutter: .75rem;
$row-spacing: 2rem;

body {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1.5rem ($gutter / 2) 12rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: rgb(84, 80, 80);

  @media (min-width: 48rem) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.main-header {
  margin: 1rem .5rem 2rem;
  text-align: center;

  @media (min-width: 48rem) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

h1 {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.5;
  margin: .75rem 0;

  .dates {
    font-weight: 300;
    opacity: .5;
    display: block;

    @media (min-width: 48rem) {
      display: inline;
    }
  }
}

a.download {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 9999px;
  color: rgba(white, .75);
  background-color: rgba(white, .1);
  transition: all .25s ease-in-out;

  &:hover {
    color: white;
    background-color: rgba(white, .15);
  }
}

div.my-gallery {
  display: flex;
  flex-wrap: wrap;

  figure {
    display: block;
    width: calc(33.3333% - #{$gutter});
    margin-top: $row-spacing / 2;
    margin-bottom: $row-spacing / 2;
    margin-left: $gutter / 2;
    margin-right: $gutter / 2;

    @media (min-width: 36rem) {
      width: calc(25% - #{$gutter});
    }

    @media (min-width: 48rem) {
      width: calc(20% - #{$gutter});
    }

    @media (min-width: 60rem) {
      width: calc(16.6667% - #{$gutter});
    }

    @media (min-width: 72rem) {
      width: calc(12.5% - #{$gutter});
    }

    a {
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      img {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all .5s ease-out;
        filter: drop-shadow(0 1px 2px rgba(black, .35));
      }

      &:hover img, &:focus img {
        filter: drop-shadow(0 4px 8px rgba(black, .5));
      }
    }
  }
}
</style>
